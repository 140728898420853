import { Card } from "antd";
import { ShareForm } from "components";
import React from "react";

const ShareApplication = () => {
  return (
    <div>
      <Card title="Apply For Shares">
        <ShareForm />
      </Card>
    </div>
  );
};

export default ShareApplication;
