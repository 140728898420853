import { API } from "../../apiSlice";

export const depositMoneyApiSlice = API.injectEndpoints({
  endpoints: (builder) => ({
    depositMoney: builder.mutation({
      query: (data) => {
        return {
          url: "users/deposit",
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const { useDepositMoneyMutation } = depositMoneyApiSlice;
