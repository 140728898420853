import { Button, Card, Col, Modal, Row, Statistic, Table } from "antd";
import React from "react";

const Overview = () => {
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState({
    title: "",
    body: "",
  });
  return (
    <div>
      <Row gutter={[14, 16]}>
        <Col xs={24} md={14}>
          <Row gutter={[14, 18]}>
            <Col xs={24} md={12}>
              <Card>
                <Statistic title="Total Savings" value={112893.45} />
                <Button
                  type="primary"
                  className="mt-4 bg-green-500 font-sans font-semibold"
                  onClick={() => {
                    setContent({
                      title: "Make Savings Deposit",
                      body: "Savings",
                    });
                    setOpen(true);
                  }}
                >
                  Make Savings Deposit
                </Button>
              </Card>
            </Col>
            <Col xs={24} md={12}>
              {" "}
              <Card>
                <Statistic title="Total Loans" value={112893.45} />
                <Button
                  type="primary"
                  className="mt-4 bg-red-500 font-sans font-semibold"
                  onClick={() => {
                    setContent({
                      title: "Make Loan Payment",
                      body: "Loan",
                    });
                    setOpen(true);
                  }}
                >
                  Make Loan Payment
                </Button>
              </Card>
            </Col>
          </Row>
          <Card title="Announcements" className="mt-6"></Card>
        </Col>
        <Col xs={24} md={10}>
          <Card title="Recent Transactions">
            <Table
              size="small"
              pagination={{ pageSize: 10 }}
              columns={[
                {
                  title: "Date",
                  dataIndex: "date",
                  key: "date",
                },
                {
                  title: "Type",
                  dataIndex: "description",
                  key: "description",
                },
                {
                  title: "Amount",
                  dataIndex: "amount",
                  key: "amount",
                },
              ]}
              dataSource={[
                {
                  key: "1",
                  date: "2021-09-01",
                  description: "Deposit",
                  amount: 1000,
                },
                {
                  key: "2",
                  date: "2021-09-02",
                  description: "Loan Payment",
                  amount: 500,
                },
                {
                  key: "3",
                  date: "2021-09-03",
                  description: "Deposit",
                  amount: 2000,
                },

                {
                  key: "4",
                  date: "2021-09-04",
                  description: "Loan Payment",
                  amount: 1000,
                },

                {
                  key: "5",
                  date: "2021-09-05",
                  description: "Deposit",
                  amount: 3000,
                },

                {
                  key: "6",
                  date: "2021-09-06",
                  description: "Loan Payment",
                  amount: 2000,
                },

                {
                  key: "7",
                  date: "2021-09-07",
                  description: "Deposit",
                  amount: 4000,
                },

                {
                  key: "8",
                  date: "2021-09-08",
                  description: "Loan Payment",
                  amount: 3000,
                },

                {
                  key: "9",
                  date: "2021-09-09",
                  description: "Deposit",
                  amount: 5000,
                },

                {
                  key: "10",
                  date: "2021-09-10",
                  description: "Loan Payment",
                  amount: 4000,
                },

                {
                  key: "11",
                  date: "2021-09-11",
                  description: "Deposit",
                  amount: 6000,
                },

                {
                  key: "12",
                  date: "2021-09-12",
                  description: "Loan Payment",
                  amount: 5000,
                },

                {
                  key: "13",
                  date: "2021-09-13",
                  description: "Deposit",
                  amount: 7000,
                },

                {
                  key: "14",
                  date: "2021-09-14",
                  description: "Loan Payment",
                  amount: 6000,
                },

                {
                  key: "15",
                  date: "2021-09-15",
                  description: "Deposit",
                  amount: 8000,
                },

                {
                  key: "16",
                  date: "2021-09-16",
                  description: "Loan Payment",
                  amount: 7000,
                },

                {
                  key: "17",
                  date: "2021-09-17",
                  description: "Deposit",
                  amount: 9000,
                },

                {
                  key: "18",
                  date: "2021-09-18",
                  description: "Loan Payment",
                  amount: 8000,
                },

                {
                  key: "19",
                  date: "2021-09-19",
                  description: "Deposit",
                  amount: 10000,
                },

                {
                  key: "20",
                  date: "2021-09-20",
                  description: "Loan Payment",
                  amount: 9000,
                },
              ]}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title={content.title}
        loading={false}
        open={open}
        okText={content.title}
        onOk={() => {}}
        onCancel={() => {
          setOpen(false);
        }}
        maskClosable={false}
      >
        <p>Form</p>
      </Modal>
    </div>
  );
};

export default Overview;
