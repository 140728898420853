import { API } from "../../apiSlice";

export const getLoanProductsApiSlice = API.injectEndpoints({
  endpoints: (builder) => ({
    getLoanProducts: builder.query({
      query: () => {
        return {
          url: "users/loan-products",
        };
      },
    }),
  }),
});

export const { useGetLoanProductsQuery } = getLoanProductsApiSlice;
