import React from "react";
import "App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import { ForgotPassword, LoginForm, Register, ResetPassword } from "components";

import {
  Accounts,
  AuthPage,
  Dashboard,
  Deposit,
  LoanApplication,
  Overview,
  Profile,
  ShareApplication,
  Transactions,
  VerifyEmail,
  Withdraw,
} from "./pages";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "store/slices";
import { LoansPage } from "pages/Admin";

function App() {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  return (
    <Routes>
      <Route path="/" element={<AuthPage />}>
        <Route index element={<LoginForm />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verify-email/:token" element={<VerifyEmail />} />
        <Route path="/register" element={<Register />} />
      </Route>
      <Route
        path="/dashboard/*"
        element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />}
      >
        <Route index element={<Navigate to="overview" />} />
        <Route path="overview" element={<Overview />} />
        <Route path="accounts" element={<Accounts />} />
        <Route path="submit-deposit" element={<Deposit />} />
        <Route path="apply-for-loan" element={<LoanApplication />} />
        <Route path="apply-for-shares" element={<ShareApplication />} />
        <Route path="loan-products" element={<LoansPage />} />
        <Route path="transactions" element={<Transactions />} />
        <Route path="request-withdraw" element={<Withdraw />} />
        <Route path="profile" element={<Profile />} />
      </Route>
    </Routes>
  );
}

export default App;
