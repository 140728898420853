import {
  BaseQueryApi,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { logOut } from "../slices/authSlice";
import { notification } from "antd";

export const getBaseURL = () => {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:5000/api/";
  }
  return "https://kokhav-investment-club-backend.nevasacomptech.com/api/";
};

const QUERY = fetchBaseQuery({
  baseUrl: getBaseURL(),
  prepareHeaders: (headers, { getState }) => {
    const { accessToken } = (getState() as { auth: { accessToken: string } })
      .auth;
    if (accessToken != null) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }

    return headers;
  },
});

const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {},
) => {
  const result = await QUERY(args, api, extraOptions);

  console.log("result", result);

  if (
    (result.error && result.error.status === 401) ||
    (result.error && result.error.status === 403)
  ) {
    // Dispatch logout action
    api.dispatch(logOut());
    // Show toast notification
    notification.error({
      message: "Session expired",
      description: "Please login again",
    });
  }

  return result;
};

export const API = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  keepUnusedDataFor: 0.0001,
  tagTypes: [],
});
