import { API } from "../../apiSlice";

export const registerUserApiSlice = API.injectEndpoints({
  endpoints: (builder) => ({
    registerUser: builder.mutation({
      query: (data) => {
        return {
          url: "users/register",
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const { useRegisterUserMutation } = registerUserApiSlice;
