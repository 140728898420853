import React from "react";
import { Button, Form, Input, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useRegisterUserMutation } from "store/api";

type FieldType = {
  username?: string;
  password?: string;
  email?: string;
  phone?: string;
  confirmPassword?: string;
  serviceProvider: string;
};

const Register = () => {
  const navigate = useNavigate();
  const [registerUser, { isLoading }] = useRegisterUserMutation();

  const onFinish = async (values: any) => {
    try {
      const res = await registerUser(values).unwrap();

      notification.success({
        message: "Registration Success",
        description: res?.message,
      });
      navigate("/");
    } catch (error) {
      notification.error({
        message: "Registration Failed",
        description: (error as any).data?.error,
      });
    }
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Create an account</h1>
      <p className="mb-4">Enter your details below to create your account</p>
      <Form name="basic" onFinish={onFinish} autoComplete="off">
        <Form.Item
          label="Financial Service Provider"
          layout="vertical"
          name="serviceProvider"
          rules={[
            { required: true, message: "Please input your financial service" },
          ]}
          initialValue={
            window.location.hostname
              .replace(/-/g, " ")
              .replace(/.nevasacomptech.com/g, " ") ?? ""
          }
          className="md:mb-14"
        >
          <Input className="bg-slate-100 h-10" disabled />
        </Form.Item>
        <Form.Item
          label="Full Name"
          layout="vertical"
          name="username"
          rules={[{ required: true, message: "Please input your full name!" }]}
          className="md:mb-14"
        >
          <Input className="bg-slate-100 h-10" />
        </Form.Item>

        <Form.Item<FieldType>
          label="Email"
          layout="vertical"
          name="email"
          rules={[{ required: true, message: "Please input your username!" }]}
          className="md:mb-14"
        >
          <Input className="bg-slate-100 h-10" />
        </Form.Item>

        <Form.Item<FieldType>
          label="Phone Number"
          layout="vertical"
          name="phone"
          rules={[{ required: true, message: "Please input your username!" }]}
          className="md:mb-14 "
        >
          <Input className="bg-slate-100 h-10" />
        </Form.Item>

        <Form.Item<FieldType>
          label="Password"
          name="password"
          layout="vertical"
          rules={[{ required: true, message: "Please input your password!" }]}
          className="md:mb-14"
        >
          <Input.Password className="bg-slate-100 h-10" />
        </Form.Item>
        <Form.Item<FieldType>
          label="Confirm Password"
          name="confirmPassword"
          layout="vertical"
          rules={[{ required: true, message: "Please input your password!" }]}
          className="md:mb-14"
        >
          <Input.Password className="bg-slate-100 h-10" />
        </Form.Item>

        <Form.Item className="mb-3 sm:mt-6">
          <Button
            type="primary"
            htmlType="submit"
            className="w-full"
            loading={isLoading}
          >
            Create Account
          </Button>
        </Form.Item>
      </Form>
      <div className="flex items-center justify-end p-3 gap-2">
        Already have an account?{" "}
        <Link to="/" className="text-blue-500">
          Login
        </Link>
      </div>
    </div>
  );
};

export default Register;
