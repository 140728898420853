import React from "react";
import { Card } from "antd";
import { useSelector } from "react-redux";
import { selectUser } from "store/slices";

const Profile = () => {
  const user = useSelector(selectUser);

  return (
    <div>
      <Card title="Profile">
        <div className="flex flex-col gap-4">
          <div>
            <p className="text-sm">Account Number</p>
            <p className="font-semibold">
              {user?.accountNumber ?? "Not assigned"}
            </p>
          </div>
          <div>
            <p className="text-sm">Full Name</p>
            <p className="font-semibold capitalize">
              {user?.username ?? "No username"}
            </p>
          </div>
          <div>
            <p className="text-sm">Email</p>
            <p className="font-semibold">
              <a href={`mailto:${user?.email}`}>{user?.email ?? "No email"}</a>
            </p>
          </div>

          <div>
            <p className="text-sm">Sacco Name</p>
            <p className="font-semibold">
              {user?.serviceProvider ?? "No group"}
            </p>
          </div>
          <div>
            <p className="text-sm">Shares</p>
            <p className="font-semibold">{user?.shares ?? "No shares"}</p>
          </div>

          <div>
            <p className="text-sm">Activation Date</p>
            <p className="font-semibold">
              {user?.activationDate ?? "Not Activated"}
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Profile;
