import { API } from "store/api/apiSlice";

export const verifyEmailApiSlice = API.injectEndpoints({
  endpoints: (builder) => ({
    verifyEmail: builder.query({
      query: (token) => {
        return {
          url: `users/verify-email/?token=${token}`,
        };
      },
    }),
  }),
});

export const { useVerifyEmailQuery } = verifyEmailApiSlice;
