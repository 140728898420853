import { notification, Spin } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useVerifyEmailQuery } from "store/api";

const VerifyEmail = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useVerifyEmailQuery(token);

  if (data?.status === 200 && !isLoading) {
    notification.success({
      message: "Email Verified",
      description: data?.message,
    });

    navigate("/");
  }

  return (
    <div className="">
      <Spin tip="Verifying Your Account" size="large" spinning={false}>
        {""}
      </Spin>
    </div>
  );
};

export default VerifyEmail;
