import {
  DownOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import { logOut, selectUser } from "store/slices";

const KicHeader = ({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  const logoutHandler = () => {
    dispatch(logOut());
    navigate("/");
  };

  const items = [
    {
      label: <Link to="/dashboard/profile">Profile</Link>,
      key: "0",
    },
    {
      label: <Link to="/dashboard/transactions">Transactions</Link>,
      key: "1",
    },
    {
      label: (
        <button
          type="button"
          onClick={logoutHandler}
          style={{
            border: "none",
            backgroundColor: "transparent",
            color: "rgb(49, 56, 62)",
            cursor: "pointer",
            width: "100%",
            textAlign: "left",
          }}
        >
          Logout
        </button>
      ),
      key: "3",
    },
  ];
  return (
    <>
      <div className="max-sm:hidden flex items-center justify-between ">
        {" "}
        <Button
          type="text"
          className="header__button"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          style={{
            fontSize: "16px",
            width: 64,
            height: 64,
          }}
        />
        <div>
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
            className="!font-sans"
          >
            <button type="button" onClick={(e) => e.preventDefault()}>
              <Space>
                <div
                  style={{
                    height: 42,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 8,
                    backgroundColor: "rgba(49, 56, 62, 0.05)",
                    padding: "12px",
                    borderRadius: "8px",
                    color: "rgb(49, 56, 62)",
                  }}
                >
                  <h4 className="font-sans font-bold capitalize">
                    {user?.username}
                  </h4>
                  <DownOutlined />
                </div>
              </Space>
            </button>
          </Dropdown>
        </div>
      </div>
      <div className="sm:hidden flex items-center justify-end">
        <button type="button" onClick={logoutHandler}>
          <LogoutOutlined />
        </button>
      </div>
    </>
  );
};

export default KicHeader;
