import React from "react";
import { Card, Table, Tabs } from "antd";
import { useGetUserDepositsQuery } from "store/api";

const Accounts = () => {
  const { data, isFetching } = useGetUserDepositsQuery("deposit");

  console.log(data);

  return (
    <div>
      <Card title="Accounts" className="!font-sans">
        <Tabs defaultActiveKey="1" className="font-sans">
          <Tabs.TabPane tab="Loans" key="1">
            <Table
              size="small"
              pagination={{ pageSize: 10 }}
              columns={[
                {
                  title: "Status",
                  dataIndex: "status",
                  key: "status",
                  render: (text) => (
                    <span
                      className={`${
                        text === "Active" ? "text-green-500" : "text-red-500"
                      }`}
                    >
                      {text}
                    </span>
                  ),
                },
                {
                  title: "Account Number",
                  dataIndex: "accountNumber",
                  key: "accountNumber",
                },
                {
                  title: "Loan Amount",
                  dataIndex: "loanAmount",
                  key: "loanAmount",
                },
                {
                  title: "Original Loan",
                  dataIndex: "originalLoan",
                  key: "originalLoan",
                },
                {
                  title: "Loan Balance",
                  dataIndex: "loanBalance",
                  key: "loanBalance",
                },
                {
                  title: "Amount Paid",
                  dataIndex: "amountPaid",
                  key: "amountPaid",
                },
                {
                  title: "Type",
                  dataIndex: "type",
                  key: "type",
                },
              ]}
              dataSource={[
                {
                  key: "1",
                  status: "Active",
                  accountNumber: "123456789",
                  loanAmount: "100000",
                  originalLoan: "100000",
                  loanBalance: "0",
                  amountPaid: "100000",
                  type: "Personal",
                },
                {
                  key: "2",
                  status: "Inactive",
                  accountNumber: "123456789",
                  loanAmount: "100000",
                  originalLoan: "100000",
                  loanBalance: "0",
                  amountPaid: "100000",
                  type: "Personal",
                },
                {
                  key: "3",
                  status: "Active",
                  accountNumber: "123456789",
                  loanAmount: "100000",
                  originalLoan: "100000",
                  loanBalance: "0",
                  amountPaid: "100000",
                  type: "Personal",
                },
                {
                  key: "4",
                  status: "Active",
                  accountNumber: "123456789",
                  loanAmount: "100000",
                  originalLoan: "100000",
                  loanBalance: "0",
                  amountPaid: "100000",
                  type: "Personal",
                },
                {
                  key: "5",
                  status: "Active",
                  accountNumber: "123456789",
                  loanAmount: "100000",
                  originalLoan: "100000",
                  loanBalance: "0",
                  amountPaid: "100000",
                  type: "Personal",
                },
                {
                  key: "6",
                  status: "Active",
                  accountNumber: "123456789",
                  loanAmount: "100000",
                  originalLoan: "100000",
                  loanBalance: "0",
                  amountPaid: "100000",
                  type: "Personal",
                },
              ]}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Savings" key="2">
            <Table
              size="small"
              loading={isFetching}
              pagination={{ pageSize: 10 }}
              columns={[
                { title: "Transaction ID", dataIndex: "id", key: "id" },
                {
                  title: "Account Number",
                  dataIndex: "accountNumber",
                  key: "accountNumber",
                },
                {
                  title: "Savings",
                  dataIndex: "amount",
                  key: "amount",
                },
                {
                  title: "Transaction Date",
                  dataIndex: "transactionDate",
                  key: "transactionDate",
                },
                {
                  title: "Status",
                  dataIndex: "status",
                  key: "status",
                  render: (text) => (
                    <span
                      className={`${
                        text === "Active" ? "text-green-500" : "text-red-500"
                      }`}
                    >
                      {text}
                    </span>
                  ),
                },
              ]}
              dataSource={data || []}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Share" key="3">
            {/* status account Number account date total shares purchased price amount received */}

            <Table
              size="small"
              pagination={{ pageSize: 10 }}
              columns={[
                {
                  title: "Status",
                  dataIndex: "status",
                  key: "status",
                  render: (text) => (
                    <span
                      className={`${
                        text === "Active" ? "text-green-500" : "text-red-500"
                      }`}
                    >
                      {text}
                    </span>
                  ),
                },
                {
                  title: "Account",
                  dataIndex: "account",
                  key: "account",
                },
                {
                  title: "Account Number",
                  dataIndex: "accountNumber",
                  key: "accountNumber",
                },
                {
                  title: "Date",
                  dataIndex: "date",
                  key: "date",
                },
                {
                  title: "Total Shares Purchased",
                  dataIndex: "totalSharesPurchased",
                  key: "totalSharesPurchased",
                },
                {
                  title: "Price",
                  dataIndex: "price",
                  key: "price",
                },
                {
                  title: "Amount Received",
                  dataIndex: "amountReceived",
                  key: "amountReceived",
                },
              ]}
              dataSource={[
                {
                  key: "1",
                  status: "Active",
                  account: "Share",
                  accountNumber: "123456789",
                  date: "2021-09-01",
                  totalSharesPurchased: "1000",
                  price: "100",
                  amountReceived: "100000",
                },
                {
                  key: "2",
                  status: "Inactive",
                  account: "Share",
                  accountNumber: "123456789",
                  date: "2021-09-01",
                  totalSharesPurchased: "1000",
                  price: "100",
                  amountReceived: "100000",
                },
                {
                  key: "3",
                  status: "Active",
                  account: "Share",
                  accountNumber: "123456789",
                  date: "2021-09-01",
                  totalSharesPurchased: "1000",
                  price: "100",
                  amountReceived: "100000",
                },
                {
                  key: "4",
                  status: "Active",
                  account: "Share",
                  accountNumber: "123456789",
                  date: "2021-09-01",
                  totalSharesPurchased: "1000",
                  price: "100",
                  amountReceived: "100000",
                },
                {
                  key: "5",
                  status: "Active",
                  account: "Share",
                  accountNumber: "123456789",
                  date: "2021-09-01",
                  totalSharesPurchased: "1000",
                  price: "100",
                  amountReceived: "100000",
                },
                {
                  key: "6",
                  status: "Active",
                  account: "Share",
                  accountNumber: "123456789",
                  date: "2021-09-01",
                  totalSharesPurchased: "1000",
                  price: "100",
                  amountReceived: "100000",
                },
              ]}
            />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default Accounts;
