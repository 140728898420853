import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: "",
  isAuthenticated: false,
  user: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, { payload }) => {
      state.accessToken = payload.accessToken;
      state.user = payload.user;
      state.isAuthenticated = true;
    },
    logOut: (state) => {
      state.accessToken = "";
      state.isAuthenticated = false;
      state.user = {};
    },
  },
});

export const { login, logOut } = authSlice.actions;

export const selectIsAuthenticated = (state: any) => state.auth.isAuthenticated;

export const selectAccessToken = (state: any) => state.auth.accessToken;

export const selectUser = (state: any) => state.auth.user;

export default authSlice.reducer;
