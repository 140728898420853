import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { API } from "./api/apiSlice";
import authReducer from "./slices/authSlice";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Choose your storage engine

const persistConfig = {
  key: "root",
  storage,
};

const reducers = combineReducers({
  [API.reducerPath]: API.reducer,
  auth: authReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare({
      serializableCheck: false,
    }).concat(API.middleware),

  devTools: true, // false for production
});

export const persistor = persistStore(store);
