import { Card } from "antd";
import { LoanForm } from "components";
import React from "react";

const LoanApplication = () => {
  return (
    <div>
      <Card title="Apply for Loan">
        <LoanForm />
      </Card>
    </div>
  );
};

export default LoanApplication;
