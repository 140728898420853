import React from "react";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { FormProps } from "antd";

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

const WithdrawForm = () => {
  const navigate = useNavigate();
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    navigate("/dashboard");
    console.log("Success:", values);
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo,
  ) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row gutter={[14, 16]}>
          <Col xs={24} md={12}>
            <Form.Item<FieldType>
              label="Select Account Type"
              layout="vertical"
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
              className="mb-12 sm:mb-14 "
            >
              <Select
                defaultValue="savings"
                allowClear
                options={[
                  { value: "savings", label: "Savings" },
                  { value: "loan", label: "Loan" },
                ]}
                placeholder="select it"
                className="bg-slate-100 !w-full h-10"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item<FieldType>
              label="Amount"
              name="password"
              layout="vertical"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
              className="sm:mb-14 "
            >
              <Input className="bg-slate-100 h-10" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[14, 16]}>
          <Col xs={24} md={12}>
            <Form.Item<FieldType>
              label="Attach reason"
              name="password"
              layout="vertical"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
              className="sm:mb-14 "
            >
              <Input className="bg-slate-100 h-10" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item<FieldType>
              label="Select Payment Type"
              layout="vertical"
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
              className="mb-12 sm:mb-14 "
            >
              <Select
                defaultValue="mobile"
                allowClear
                options={[
                  { value: "mobile", label: "Mobile Money" },
                  { value: "bank", label: "Bank Transfer" },
                ]}
                placeholder="select it"
                className="bg-slate-100 !w-full h-10"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item className="mb-3">
          <Button type="primary" htmlType="submit" className="w-full">
            Apply For Shares
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default WithdrawForm;
