import React from "react";
import { Layout, Menu } from "antd";
import {
  AppstoreAddOutlined,
  DollarOutlined,
  FileDoneOutlined,
  FileSearchOutlined,
  SolutionOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { KICFooter, KICHeader } from "../../components/Common";

import { NavLink, Outlet, useLocation } from "react-router-dom";

const Dashboard = () => {
  const { Header, Footer, Sider, Content } = Layout;
  const location = useLocation();
  const active = location.pathname.split("/")[2];

  console.log(active);

  const [collapsed, setCollapsed] = React.useState(false);

  const headerStyle: React.CSSProperties = {
    height: 64,
    paddingInline: 12,
    lineHeight: "64px",
    position: "fixed",
    zIndex: 1,
    width: "100%",
    maxWidth: "100%",
    top: 0,
    left: 0,
    paddingLeft: collapsed ? 80 : 250,
    transition: "all 0.3s ease-in-out",
  };

  const contentStyle: React.CSSProperties = {
    paddingInline: 12,
    paddingTop: 12,
    paddingBottom: 12,
    minHeight: 120,
    lineHeight: "120px",
    marginTop: 64,
    marginLeft: collapsed ? 80 : 250,
    transition: "all 0.3s ease-in-out",
  };

  const siderStyle: React.CSSProperties = {
    textAlign: "left",
    lineHeight: "120px",
    position: "fixed",
    zIndex: 2,
    height: "100vh",

    left: 0,
  };

  const footerStyle: React.CSSProperties = {
    textAlign: "right",
  };

  const layoutStyle = {
    overflow: "hidden",
    width: "100%",
    maxWidth: "100%",
  };

  const menuItems = [
    {
      label: "Dashboard",
      icon: <AppstoreAddOutlined />,
      link: "overview",
      key: "1",
    },
    {
      label: "Accounts",
      icon: <SolutionOutlined />,
      link: "accounts",
      key: "2",
    },
    {
      label: "Submit a Deposit",
      icon: <WalletOutlined />,
      link: "submit-deposit",
      key: "3",
    },
    {
      label: "Loan Products",
      icon: <FileDoneOutlined />,
      link: "loan-products",
      key: "4",
    },
    {
      label: "Apply For Loan",
      icon: <FileDoneOutlined />,
      link: "apply-for-loan",
      key: "5",
    },
    {
      label: "Apply For Shares",
      icon: <UsergroupAddOutlined />,
      link: "apply-for-shares",
      key: "6",
    },
    {
      label: "Transactions",
      icon: <FileSearchOutlined />,
      link: "transactions",
      key: "7",
    },
    {
      label: "Request Withdraw",
      icon: <DollarOutlined />,
      link: "request-withdraw",
      key: "8",
    },
    {
      label: "Profile",
      icon: <UserOutlined />,
      link: "profile",
      key: "9",
    },
  ];
  return (
    <Layout style={layoutStyle} className=" relative">
      <Sider
        width="250px"
        style={siderStyle}
        trigger={null}
        collapsible
        collapsed={collapsed}
        className="bg-white h-screen"
      >
        <div className="text-center h-16 bg-white ">
          <h2 className="  text-2xl font-bold text-gray-800 leading-8 py-4 px-2 border border-b-2">
            KIC
          </h2>
        </div>
        <Menu
          className="bg-white w-full h-full font-sans font-medium"
          theme="light"
          mode="inline"
          defaultSelectedKeys={["overview"]}
          selectedKeys={[active]}
        >
          {menuItems.map((item) => (
            <Menu.Item key={item.link} icon={item.icon}>
              <NavLink to={item.link}>{item.label}</NavLink>
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout>
        <Header style={headerStyle} className="bg-white">
          <KICHeader collapsed={collapsed} setCollapsed={setCollapsed} />
        </Header>
        <Content style={contentStyle}>
          <Outlet />
        </Content>
        <Footer style={footerStyle} className="bg-white">
          <KICFooter />
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
