import React from "react";

import type { FormProps } from "antd";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Select,
} from "antd";
import { useDepositMoneyMutation } from "store/api";
import { useSelector } from "react-redux";
import { selectUser } from "store/slices";

type FieldType = {
  accountNumber?: string;
  password?: string;
  accountType?: string;
  documentName?: string;
  documentDescription?: string;
  amount?: string;
  transactionDate?: string;
  transactionId?: string;
};

const DepositForm: React.FC = () => {
  const [depositMoney, { isLoading }] = useDepositMoneyMutation();
  const user = useSelector(selectUser);

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    try {
      await depositMoney({ id: "16", ...values }).unwrap();
      notification.success({
        message: "Deposit Success",
        description: "You have successfully deposited money",
      });
      window.location.reload();
    } catch (error) {
      notification.error({
        message: "Deposit Failed",
        description: "Please try again",
      });
    }
  };

  return (
    <div>
      <Form name="basic" onFinish={onFinish} autoComplete="off">
        <Row gutter={[14, 16]}>
          <Col xs={24} md={12}>
            <Form.Item<FieldType>
              label="Select Account Type"
              layout="vertical"
              name="accountType"
              rules={[
                { required: true, message: "Please select account type" },
              ]}
              className="mb-12 sm:mb-14 "
            >
              <Select
                defaultValue="savings"
                allowClear
                options={[
                  { value: "savings", label: "Savings" },
                  { value: "loan", label: "Loan" },
                ]}
                placeholder="select it"
                className="bg-slate-100 !w-full h-10"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item<FieldType>
              label="select Account Number"
              layout="vertical"
              name="accountNumber"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
              className="mb-12 sm:mb-14 "
            >
              <Select
                defaultValue=""
                allowClear
                options={[
                  { value: "", label: "Select Account Number" },
                  { value: user?.accountNumber, label: user?.accountNumber },
                  { value: "00011223344557", label: "00011223344557" },
                ]}
                placeholder="select it"
                className="bg-slate-100 w-full h-10"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[14, 16]}>
          <Col xs={24} md={12}>
            <Form.Item<FieldType>
              label="Amount"
              layout="vertical"
              name="amount"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
              className="mb-12 sm:mb-14 "
            >
              <Input className="bg-slate-100 h-10" type="number" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              className="mb-3"
              label="Select Account"
              layout="vertical"
            >
              <Button type="primary" htmlType="submit" className="w-full h-10">
                Add Extra Account
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[14, 16]}>
          <Col xs={24} md={12}>
            <Form.Item<FieldType>
              label="Transaction Date"
              layout="vertical"
              name="transactionDate"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
              className="mb-12 sm:mb-14 "
            >
              <DatePicker className="w-full h-10" onChange={() => {}} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item<FieldType>
              label="Transaction ID"
              layout="vertical"
              name="transactionId"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
              className="mb-12 sm:mb-14 "
            >
              <Input className="bg-slate-100 h-10" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[14, 16]}>
          <Col xs={24} md={12}>
            <Form.Item<FieldType>
              label="Document Name"
              layout="vertical"
              name="documentName"
              rules={[{ required: true, message: " " }]}
              className="mb-12 sm:mb-14 "
            >
              <Input className="bg-slate-100 h-10" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item<FieldType>
              label="Document Description"
              layout="vertical"
              name="documentDescription"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
              className="mb-12 sm:mb-14 "
            >
              <Input className="bg-slate-100 h-10" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item className="mb-3">
          <Button
            type="primary"
            htmlType="submit"
            className="w-full"
            loading={isLoading}
          >
            Deposit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DepositForm;
