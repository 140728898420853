import React, { FC } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, notification, Select } from "antd";
import { useAddLoanProductMutation } from "store/api/requests/loan/addLoanProductApiSlice";
import { LoanProductProps } from "interfaces";

interface AddLoanProductProps {
  closeModal: () => void;
}

const AddLoanProduct: FC<AddLoanProductProps> = ({ closeModal }) => {
  const [addLoanProduct, { isLoading }] = useAddLoanProductMutation();

  const onFinish = async (values: LoanProductProps) => {
    try {
      await addLoanProduct(values).unwrap();
      notification.success({
        message: "Success",
        description: "Loan product added successfully",
      });
      closeModal();
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to add loan product",
      });
    }
  };

  return (
    <div>
      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Loan Type"
          name="loanType"
          rules={[{ required: true, message: "Please select loan type" }]}
        >
          <Select
            options={[
              {
                value: "personal short term loan",
                label: "Personal Short Term Loan",
              },
              {
                value: "business short term loan",
                label: "Business Short Term Loan",
              },
            ]}
          ></Select>
        </Form.Item>
        <Form.Item
          name="interestRate"
          label="Interest Rate"
          rules={[{ required: true, message: "Please input interest rate" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="maxLoanAmount"
          label="Max Loan Amount"
          rules={[{ required: true, message: "Please input max loan amount" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="minLoanAmount"
          label="Min Loan Amount"
          rules={[{ required: true, message: "Please input min loan amount" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="loanTerm"
          label="Loan Term"
          rules={[{ required: true, message: "Please input loan term" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Repayment Every" name="repaymentEvery">
          <Input />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="w-full"
            icon={<PlusCircleOutlined />}
            loading={isLoading}
          >
            Add Loan Product
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddLoanProduct;
