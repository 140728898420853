import { API } from "../../apiSlice";

export const loanRequestApiSlice = API.injectEndpoints({
  endpoints: (builder) => ({
    loanRequest: builder.mutation({
      query: (data) => {
        return {
          url: "users/request-loan",
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const { useLoanRequestMutation } = loanRequestApiSlice;
