import React from "react";
import { Button, Card, Col, Modal, Row, Statistic, Table } from "antd";
import { Link } from "react-router-dom";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { AddLoanProductForm } from "components";
import { useGetLoanProductsQuery } from "store/api/requests/loan/getLoanProductsApiSlice";

const Loans = () => {
  const [open, setOpen] = React.useState(false);
  const { data, isFetching, isLoading, refetch } =
    useGetLoanProductsQuery("products");
  const loanProducts = data?.data?.data || [];

  return (
    <div>
      <Card loading={isFetching}>
        <Row justify="space-between">
          <Col>
            <Statistic
              title="Total Loan Products"
              value={loanProducts.length}
            />
          </Col>
          <Col>
            <div className="flex justify-end gap-8">
              <Button
                onClick={() => refetch()}
                type="text"
                className="font-semibold"
                icon={<ReloadOutlined spin={isLoading} />}
              />
              <Button
                type="primary"
                className="font-semibold"
                icon={<PlusOutlined />}
                onClick={() => setOpen(true)}
              >
                Create Loan Product
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
      <Card>
        <Table
          loading={isFetching}
          pagination={{
            defaultPageSize: 5,
            pageSizeOptions: ["10", "20", "50", "100"],
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total) => `Total ${total} items`,
          }}
          dataSource={loanProducts}
          columns={[
            {
              title: "Product Name",
              dataIndex: "loanType",
              key: "loanType",
            },
            {
              title: "Interest Rate",
              dataIndex: "interestRate",
              key: "interestRate",
            },
            {
              title: "Min Amount",
              dataIndex: "minLoanAmount",
              key: "minLoanAmount",
            },
            {
              title: "Max Amount",
              dataIndex: "maxLoanAmount",
              key: "maxLoanAmount",
            },
            {
              title: "Loan Term",
              dataIndex: "loanTerm",
              key: "loanTerm",
            },
            {
              title: "Action",
              key: "action",
              render: () => (
                <Link to="/loans" type="primary" className="w-20 capitalize">
                  View loan applications
                </Link>
              ),
            },
          ]}
        />
      </Card>
      <Modal
        title="Create Loan Product"
        visible={open}
        footer={null}
        onCancel={() => setOpen(false)}
      >
        <AddLoanProductForm closeModal={() => setOpen(false)} />
      </Modal>
    </div>
  );
};

export default Loans;
