import { API } from "../../apiSlice";

export const addLoanProductApiSlice = API.injectEndpoints({
  endpoints: (builder) => ({
    addLoanProduct: builder.mutation({
      query: (data) => {
        return {
          url: "users/create-loan-product",
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const { useAddLoanProductMutation } = addLoanProductApiSlice;
