import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  FormProps,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Upload,
} from "antd";
import { LoanProductProps } from "interfaces";
import React from "react";
import { useLoanRequestMutation } from "store/api";
import { useGetLoanProductsQuery } from "store/api/requests/loan/getLoanProductsApiSlice";

type FieldType = {
  loanType: string;
  loanAmount: string;
  interestRate: string;
  loanTerm: string;
  documentName: string;
  documentDescription: string;
  loanDescription: string;
  loanPurpose: string;
};

const LoanForm = () => {
  const [open, setOpen] = React.useState(false);
  const [loanRequest, { isLoading }] = useLoanRequestMutation();

  const { data, isFetching } = useGetLoanProductsQuery("products");

  const loanProducts = data?.data?.data || [];

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    try {
      await loanRequest(values).unwrap();
      notification.success({
        message: "Success",
        description: "Loan Requested Successfully",
      });
      window.location.reload();
    } catch (error) {
      notification.error({
        message: "Error",
        description: "An error occurred",
      });
    }
  };

  const props = {};

  return (
    <div>
      {" "}
      <Form name="basic" onFinish={onFinish} autoComplete="off">
        <Row gutter={[14, 16]}>
          <Col xs={24} md={12}>
            <Form.Item<FieldType>
              label="Select Loan"
              layout="vertical"
              name="loanType"
              rules={[{ required: true, message: "Please select loan " }]}
              className="mb-12 sm:mb-14 "
            >
              <Select
                loading={isFetching}
                defaultValue="personal short term loan"
                allowClear
                options={loanProducts.map((product: LoanProductProps) => ({
                  value: product.id,
                  label: product.loanType,
                }))}
                placeholder="select it"
                className="bg-slate-100 !w-full h-10"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item<FieldType>
              label="Purpose of Loan"
              layout="vertical"
              name="loanPurpose"
              className="mb-12 sm:mb-14 "
            >
              <Select
                defaultValue="business"
                allowClear
                options={[
                  { value: "business", label: "Business" },
                  { value: "education", label: "Education" },
                ]}
                placeholder="select it"
                className="bg-slate-100 w-full h-10"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[14, 16]}>
          <Col xs={24} md={12}>
            <Form.Item<FieldType>
              label="Principal Amount"
              layout="vertical"
              name="loanAmount"
              rules={[{ required: true, message: "Please enter loan amount" }]}
              className="mb-12 sm:mb-14 "
            >
              <Input className="bg-slate-100 h-10" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              className="mb-3"
              label="Loan Term"
              name="loanTerm"
              layout="vertical"
            >
              <Input className="bg-slate-100 h-10" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[14, 16]}>
          <Col xs={24} md={12}>
            <Form.Item<FieldType>
              label="Document Name"
              layout="vertical"
              name="documentName"
              rules={[
                { required: true, message: "Please enter document name" },
              ]}
              className="mb-12 sm:mb-14 "
            >
              <Input className="w-full h-10" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item<FieldType>
              label="Document Description"
              layout="vertical"
              name="documentDescription"
              rules={[
                { required: true, message: "Please enter documentation" },
              ]}
              className="mb-12 sm:mb-14 "
            >
              <Input className="bg-slate-100 h-10" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[14, 16]}>
          <Col xs={24} md={12}>
            <Form.Item<FieldType>
              label=" Upload Document"
              layout="vertical"
              name="documentName"
              className="mb-12 sm:mb-14 "
            >
              <Upload {...props} className="h-10 !w-full">
                <Button icon={<UploadOutlined />} className="h-10 w-full">
                  Select Document
                </Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item<FieldType>
              label="Loan Calculator"
              layout="vertical"
              name="documentDescription"
              className="mb-12 sm:mb-14 "
            >
              <Button
                className="bg-slate-100 h-10 w-full"
                onClick={() => setOpen(true)}
              >
                Calculate Loan
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[14, 16]}>
          <Col xs={24} md={12}>
            <Form.Item<FieldType>
              label="Loan Description / comment"
              layout="vertical"
              name="documentDescription"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
              className="mb-12 sm:mb-14 "
            >
              <Input className="bg-slate-100 h-10" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item className="mb-3">
          <Button
            type="primary"
            htmlType="submit"
            className="w-full"
            loading={isLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Modal
        title="Loan Calculator"
        open={open}
        onClose={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        okText="Calculate Monthly Repayment"
      >
        <Form>
          <Form.Item<FieldType>
            label="Select Loan Product"
            layout="vertical"
            name="documentDescription"
            rules={[{ required: true, message: "Please input your username!" }]}
            className="mb-12 sm:mb-14 "
          >
            <Select
              className="bg-slate-100 h-10"
              options={[
                {
                  value: "personal short term loan",
                  label: "Personal Short Term Loan",
                },
              ]}
            />
          </Form.Item>
          <Form.Item<FieldType>
            label="Loan Interest Rate"
            layout="vertical"
            name="documentDescription"
            rules={[{ required: true, message: "Please input your username!" }]}
            className="mb-12 sm:mb-14 "
          >
            <Input className="bg-slate-100 h-10" />
          </Form.Item>
          <Form.Item<FieldType>
            label="Loan Principal Amount"
            layout="vertical"
            name="documentDescription"
            rules={[{ required: true, message: "Please input your username!" }]}
            className="mb-12 sm:mb-14 "
          >
            <Input className="bg-slate-100 h-10" />
          </Form.Item>
          <Form.Item<FieldType>
            label="Repayment Period in months"
            layout="vertical"
            name="documentDescription"
            rules={[{ required: true, message: "Please input your username!" }]}
            className="mb-12 sm:mb-14 "
          >
            <Input className="bg-slate-100 h-10" />
          </Form.Item>
          <Form.Item<FieldType>
            label="Monthly Repayment Amount:"
            layout="vertical"
            name="documentDescription"
            rules={[{ required: true, message: "Please input your username!" }]}
            className="mb-12 sm:mb-14 "
          >
            <Input className="bg-slate-100 h-10" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default LoanForm;
