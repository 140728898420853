import { Card, Table, Tabs } from "antd";
import React from "react";

const Transactions = () => {
  return (
    <div>
      {" "}
      <Card title="Transactions" className="!font-sans">
        <Tabs defaultActiveKey="1" className="font-sans">
          <Tabs.TabPane tab="Recent Transactions" key="1">
            <Table
              size="small"
              pagination={{ pageSize: 10 }}
              columns={[
                {
                  title: "Account Number",
                  dataIndex: "accountNumber",
                  key: "accountNumber",
                },
                {
                  title: "Account Type",
                  dataIndex: "accountType",
                  key: "accountType",
                },
                {
                  title: "Amount",
                  dataIndex: "amount",
                  key: "amount",
                },
                {
                  title: "Transaction Type",
                  dataIndex: "transactionType",
                  key: "transactionType",
                },
                {
                  title: "Transaction Date",
                  dataIndex: "transactionDate",
                  key: "transactionDate",
                },
              ]}
              dataSource={[
                {
                  key: "1",
                  accountNumber: "1234567890",
                  accountType: "Savings",
                  amount: "100000",
                  transactionType: "Deposit",
                  transactionDate: "2021-09-01",
                },
                {
                  key: "2",
                  accountNumber: "1234567890",
                  accountType: "Savings",
                  amount: "100000",
                  transactionType: "Deposit",
                  transactionDate: "2021-09-01",
                },
                {
                  key: "3",
                  accountNumber: "1234567890",
                  accountType: "Savings",
                  amount: "100000",
                  transactionType: "Deposit",
                  transactionDate: "2021-09-01",
                },
                {
                  key: "4",
                  accountNumber: "1234567890",
                  accountType: "Savings",
                  amount: "100000",
                  transactionType: "Deposit",
                  transactionDate: "2021-09-01",
                },
                {
                  key: "5",
                  accountNumber: "1234567890",
                  accountType: "Savings",
                  amount: "100000",
                  transactionType: "Deposit",
                  transactionDate: "2021-09-01",
                },
                {
                  key: "6",
                  accountNumber: "1234567890",
                  accountType: "Savings",
                  amount: "100000",
                  transactionType: "Deposit",
                  transactionDate: "2021-09-01",
                },
              ]}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Self Reported Transactions" key="2">
            <Table
              size="small"
              pagination={{ pageSize: 10 }}
              columns={[
                {
                  title: "Status",
                  dataIndex: "status",
                  key: "status",
                  render: (text) => (
                    <span
                      className={`${
                        text === "Active" ? "text-green-500" : "text-red-500"
                      }`}
                    >
                      {text}
                    </span>
                  ),
                },
                {
                  title: "Amount",
                  dataIndex: "amount",
                  key: "amount",
                },
                {
                  title: "Transaction Date",
                  dataIndex: "transactionDate",
                  key: "transactionDate",
                },
              ]}
              dataSource={[
                {
                  key: "1",
                  status: "Active",
                  amount: "100000",
                  transactionDate: "2021-09-01",
                },
                {
                  key: "2",
                  status: "Inactive",
                  amount: "100000",
                  transactionDate: "2021-09-01",
                },
                {
                  key: "3",
                  status: "Active",
                  amount: "100000",
                  transactionDate: "2021-09-01",
                },
                {
                  key: "4",
                  status: "Active",
                  amount: "100000",
                  transactionDate: "2021-09-01",
                },
                {
                  key: "5",
                  status: "Active",
                  amount: "100000",
                  transactionDate: "2021-09-01",
                },
                {
                  key: "6",
                  status: "Active",
                  amount: "100000",
                  transactionDate: "2021-09-01",
                },
              ]}
            />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default Transactions;
