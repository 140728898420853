import React from "react";

import type { FormProps } from "antd";
import { Button, Form, Input, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useLoginUserMutation } from "store/api";
import { useDispatch } from "react-redux";
import { login } from "store/slices/index";

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
  serviceProvider?: string;
};

const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loginUser, { isLoading }] = useLoginUserMutation();

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    try {
      const res = await loginUser({
        email: values.username,
        password: values.password,
      }).unwrap();

      if (res?.user?.isVerified === "true") {
        dispatch(
          login({
            accessToken: res.token,
            user: res.user,
            isAuthenticated: true,
          }),
        );

        notification.success({
          message: "Login Success",
          description: "You have successfully logged in",
        });

        navigate("/dashboard");
      } else {
        notification.error({
          message: "Login Failed",
          description: "Please verify your email address",
        });
      }
    } catch (error) {
      notification.error({
        message: "Login Failed",
        description: "Invalid username or password",
      });
    }
  };

  return (
    <div>
      <div>
        <h1 className="text-2xl font-bold mb-4">Welcome Back!</h1>
        <p className="mb-4">Login to your account</p>
      </div>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item<FieldType>
          label="Service Provider"
          layout="vertical"
          name="serviceProvider"
          rules={[{ required: true, message: "Please input your username!" }]}
          initialValue={
            window.location.hostname
              .replace(/-/g, " ")
              .replace(/.nevasacomptech.com/g, " ") ?? ""
          }
          className="md:mb-14 "
        >
          <Input className="bg-slate-100 h-10" disabled />
        </Form.Item>
        <Form.Item<FieldType>
          label="Email"
          layout="vertical"
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
          className="md:mb-14 "
        >
          <Input className="bg-slate-100 h-10" />
        </Form.Item>

        <Form.Item<FieldType>
          label="Password"
          name="password"
          layout="vertical"
          rules={[{ required: true, message: "Please input your password!" }]}
          className="md:mb-14 "
        >
          <Input.Password className="bg-slate-100 h-10" />
        </Form.Item>

        <div className=" flex items-center justify-end p-3 ">
          <Link to="/forgot-password" className="text-blue-500">
            Forgot password?
          </Link>
        </div>

        <Form.Item className="mb-3">
          <Button
            type="primary"
            htmlType="submit"
            className="w-full h-10"
            loading={isLoading}
          >
            Login
          </Button>
        </Form.Item>
      </Form>

      <div className=" flex items-center justify-end p-3 gap-2 ">
        Don't have an account?{" "}
        <Link to="/register" className="text-blue-500">
          Register
        </Link>
      </div>
    </div>
  );
};

export default LoginForm;
