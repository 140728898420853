import { Card } from "antd";
import React from "react";
import { DepositForm } from "../../components";

const Deposit = () => {
  return (
    <div>
      <Card title="Submit A Deposit">
        <DepositForm />
      </Card>
    </div>
  );
};

export default Deposit;
