import React from "react";
import { Card } from "antd";
import { WithdrawForm } from "components";

const Withdraw = () => {
  return (
    <div>
      <Card title="Withdraw">
        <WithdrawForm />
      </Card>
    </div>
  );
};

export default Withdraw;
