import { NavLink } from "react-router-dom";
import {
  AppstoreAddOutlined,
  BarChartOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
const KICFooter = () => {
  const year = new Date().getFullYear();

  const menuItems = [
    { title: "Dashboard", icon: <AppstoreAddOutlined />, link: "overview" },
    { title: "Events", icon: <UnorderedListOutlined />, link: "events" },
    { title: "Stats", icon: <BarChartOutlined />, link: "stats" },
    { title: "Profile", icon: <UserOutlined />, link: "profile" },
  ];

  return (
    <div>
      <div className="mookh-footer">
        <p>© {year} All Rights Reserved By Nevasa Comptech</p>
      </div>
      <div className="hidden">
        <nav>
          <ul className="list">
            {menuItems.map((item, index) => (
              <li key={index}>
                <NavLink
                  to={`/dashboard/${item.link}`}
                  className={({ isActive }) =>
                    "link" + (isActive ? " link--active" : "")
                  }
                >
                  {item.icon}
                  <span>{item.title}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default KICFooter;
